
/* .el-input--small .el-input__inner {
  height: 28px;
  line-height: 28px;
}
.el-input--medium .el-input__inner {
  height: 28px;
  line-height: 28px;
} */
.xiaxuan{
  cursor: pointer;
  color: black;
}
.filter-item {
  margin-right: 10px;
}
.table-head{ width: 1800px; background-color:#999;color:#000;}
/* .table-body{ width: 1800px; height:500px;overflow:scroll;} */
.table-body{ width: 1800px; height:500px;}
